@import "../../variables";

section.noticias {
    .cards-noticias {
        .card-noticias {
            width: 100%;
            margin-bottom: 15px;

            .img {
                width: 40%;
                background-size: cover;
                background-position: center center;
            }

            .txt {
                width: 60%;
                padding: 10px;
                color: $cinza;
                font-size: 0.85rem;
                font-weight: 500;

                h2 {
                    font-size: 0.98rem;
                    font-weight: 600;
                }

                .descricao {
                    overflow: hidden;
                    display: -moz-box;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                    max-height: 65px;
                }
            }
        }
    }
}