@import "../../variables.scss";

section.home {

    .box-destaque {
        border-bottom: solid 1px rgba($color: $cinza, $alpha: .2);
        margin-bottom: 25px;
        color: $cinza;

        .img {
            height: 50vw;
            width: 100%;
            background-size: cover;
            background-position: center center;
            border-radius: 5px;
        }

        h2 {
            font-size: 16px;
            text-transform: uppercase;
            color: $cinza;
            margin-top: 15px;
            margin-bottom: 10px;
        }

        div {
            font-size: 14px;
            font-weight: 500;

            p {
                margin: 0;
                margin-bottom: 10px;
            }
        }
    }

    .box-eventos {
        border-bottom: solid 1px rgba($color: $cinza, $alpha: .2);
        margin-bottom: 25px;

        .titulo {
            margin-bottom: 7px;

            h3 {
                font-size: 16px;
                text-transform: uppercase;
                color: $cinza;
            }
        }

        .cards-eventos {
            overflow-x: auto;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;

            .card-eventos {
                min-width: 50vw;
                margin-bottom: 15px;
                flex-flow: column;
                margin-right: 15px;

                .img {
                    height: 30vw;
                    width: 100%;
                    background-size: cover;
                    background-position: center center;
                }

                .txt {
                    width: 100%;
                    padding: 10px;
                    text-align: center;
                    color: $cinza;

                    h2 {
                        font-size: 0.98rem;
                        font-weight: 600;
                    }

                    h3 {
                        font-size: 0.85rem;
                        font-weight: 500;
                        margin: 10px;
                        justify-content: center;

                        >span {
                            margin-right: 10px;

                            span {
                                text-transform: uppercase;
                                font-size: 0.5rem;
                                display: block;
                            }
                        }
                    }

                    .tags {
                        font-size: 0.73rem;
                    }
                }
            }
        }
    }

    .box-videos {
        border-bottom: solid 1px rgba($color: $cinza, $alpha: .2);
        margin-bottom: 25px;

        .titulo {
            margin-bottom: 7px;

            h3 {
                font-size: 16px;
                text-transform: uppercase;
                color: $cinza;
            }
        }

        .cards-videos {
            overflow-x: auto;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;

            .card-videos {
                min-width: 50vw;
                margin-bottom: 15px;
                flex-flow: column;
                margin-right: 15px;

                .img {
                    height: 30vw;
                    width: 100%;
                    background-size: cover;
                    background-position: center center;
                }

                .txt {
                    width: 100%;
                    padding: 10px;
                    text-align: center;
                    color: $cinza;

                    h2 {
                        font-size: 0.98rem;
                        font-weight: 600;
                    }

                    p {
                        font-size: 0.85rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .box-noticias {
        .titulo {
            margin-bottom: 7px;

            h3 {
                font-size: 16px;
                text-transform: uppercase;
                color: $cinza;
            }
        }

        .cards-noticias {
            overflow-x: auto;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;

            .card-noticias {
                min-width: 50vw;
                margin-bottom: 15px;
                flex-flow: column;
                margin-right: 15px;

                .img {
                    height: 30vw;
                    width: 100%;
                    background-size: cover;
                    background-position: center center;
                }

                .txt {
                    width: 100%;
                    padding: 10px;
                    text-align: center;
                    color: $cinza;

                    h2 {
                        font-size: 0.98rem;
                        font-weight: 600;
                    }

                    p {
                        font-size: 0.85rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.modal-video {
    align-items: center;
    justify-content: center;
    display: flex;

    .MuiBox-root {
        justify-content: center;
        display: flex;
        height: auto;

        video {
            width: calc(100% - 30px);
            height: auto;
        }
    }
}