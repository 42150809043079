@import "../../variables";

.lojas.page {
    color: #000;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: $cinzaEscuro;
        margin-bottom: 20px;

        >.MuiButtonBase-root {
            color: $cinzaEscuro;
            border-bottom: solid 1px;
            border-radius: 0;
        }

        .esq {
            width: calc(100% - 75px);
        }

        .bt-seta {
            color: $cinzaEscuro;
        }
    }
}

.leaflet-container {
    height: calc(100vh - 245px);
    width: 100%;
    overflow: hidden;
    border: solid 1px;
}

.MuiDrawer-root.lojas-drawer {
    .MuiPaper-root.MuiPaper-elevation {
        border-radius: 7px 7px 0 0;
        width: 100%;
        height: 50vh;
    }

    .bt-busca {
        color: $cinzaEscuro;
    }
}

.MuiDrawer-root.lojas-drawer-dir {
    color: $cinza;

    >.MuiPaper-root {
        padding-top: 25px;

        .bt-voltar {
            color: $cinzaEscuro;
            margin-bottom: 20px;
        }

        &.MuiPaper-elevation,
        .MuiPaper-elevation {
            border-radius: 0;
            width: 100%;
            height: 100%;
        }

        .MuiPaper-root {
            background: $cinzaBemClaro;
            border: none;
            box-shadow: none;
            padding: 0 15px;
            margin-bottom: 15px;
            border-radius: 4px;

            &::before {
                display: none;
            }

            .MuiAccordionDetails-root {
                padding: 0;
                margin-bottom: 20px;
            }

            .titulo {
                color: $cinza;

                h3 {
                    font-size: 14px;
                    margin-bottom: 10px;

                    span {
                        color: #fff;
                        background: #000;
                        border-radius: 100px;
                        min-width: 25px;
                        min-height: 25px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 5px;
                    }
                }

                p {
                    margin: 0;
                    font-size: 12px;

                    svg {
                        position: relative;
                        top: 3px;
                    }
                }
            }

            .txt {
                color: $cinza;
                font-size: 12px;

                h3 {
                    margin-top: 20px;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                    font-size: 14px;
                }

                p {
                    margin: 0;
                    margin-bottom: 10px;

                    svg {
                        position: relative;
                        top: 3px;
                        margin-right: 10px;
                    }

                    img {
                        position: relative;
                        top: 3px;
                        margin-right: 10px;
                        width: 15px;
                        height: auto;
                    }
                }

                .bt {
                    margin-top: 15px;
                }
            }
        }
    }
}