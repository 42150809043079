@import "../../variables";

.interna-noticia.page {
    color: $cinzaEscuro;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: $cinzaEscuro;

        .bt-seta {
            color: $cinzaEscuro;
        }
    }

    img {
        width: 100%;
        border-radius: 7px;
    }

    h2 {
        font-size: 0.98rem;
        font-weight: 600;
        color: $cinzaEscuro;
        margin-top: 15px;
        margin-bottom: 5px;
    }
}