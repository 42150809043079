@import "../../variables";

header {
    padding: 10px 15px;
    padding-top: 15px;
    font-size: 20px;
    text-align: center;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 99;

    .bt-profile {
        img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
        }
    }

    .bt-logo {
        position: relative;

        small {
            position: absolute;
            color: $cinza;
            bottom: -6px;
            left: 0;
        }
    }
}

body.is-login-page {
    header {
        display: none;
    }
}

.MuiDrawer-root {
    .MuiPaper-root.MuiPaper-elevation {
        border-radius: 0 7px 7px 0;
        width: calc(100% - 70px);

        .MuiListItem-root {
            padding: 0 15px;
            width: 100%;

            .MuiListItemButton-root {
                padding: 10px;
                border-bottom: solid 1px $cinza;
                color: $cinza;
                width: 100%;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    .MuiBox-root {
        width: 100%;

        .MuiList-root {
            width: 100%;
        }
    }
}