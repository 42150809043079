@import "../../variables";

section.eventos {
    .cards-eventos {
        .card-eventos {
            width: 100%;
            margin-bottom: 15px;

            .img {
                width: 40%;
                background-size: cover;
                background-position: center center;
            }

            .txt {
                width: 60%;
                padding: 10px;
                text-align: center;
                color: $cinza;

                h2 {
                    font-size: 0.98rem;
                    font-weight: 600;
                }

                h3 {
                    font-size: 0.85rem;
                    font-weight: 500;
                    margin: 10px;
                    justify-content: center;

                    >span {
                        margin-right: 10px;

                        span {
                            text-transform: uppercase;
                            font-size: 0.5rem;
                            display: block;
                        }
                    }
                }

                .tags {
                    font-size: 0.73rem;
                }
            }
        }
    }
}