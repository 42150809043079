@import url(assets/fonts/AvenirNext/stylesheet.css);
@import url(assets/fonts/LandRoverWeb/stylesheet.css);
@import "./variables.scss";

html {
    font-size: 14px;
}

body {
    background: #fff;
    color: #000;
    font-family: 'Land Rover Web';
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    >iframe {
        display: none !important;
    }
}

* {
    outline: none;
    box-sizing: border-box;
}

a,
a:hover,
a:focus {
    text-decoration: none;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex-wr {
    flex-wrap: wrap;
}

.flex-ch {
    justify-content: center;
}

.flex-ri {
    justify-content: flex-end;
}

.flex-bv {
    align-items: flex-end;
}

.flex-le {
    justify-content: flex-start;
}

.flex-sp {
    justify-content: space-between;
}

.flex-sa {
    justify-content: last-baseline;
    padding-top: 50px;
}

.flex-cv {
    align-items: center;
}

.container {
    width: 100%;
}

// oculta scroll
* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.page {
    padding: 20px 0;
    padding-top: 5px;
    height: calc(100vh - 112px);
    overflow-y: auto;

    h1 {
        font-size: 26px;
        margin-bottom: 15px;
        color: #fff;
    }
}

.MuiSnackbarContent-root {

    .MuiSnackbarContent-message {
        font-size: 12px;
    }

    .MuiButtonBase-root {
        font-size: 12px;
        color: #fff;
    }
}

body .MuiButtonBase-root {
    min-width: 0;
    min-height: 0;
    padding: 0;
    margin: 0;
}

.bt.MuiButton-root {
    background: rgba($color: #000000, $alpha: 0);
    color: #fff;
    font-size: 16px;
    padding: 5px 20px;
    border-radius: 6px;
    border: solid 1px #fff;
    font-family: 'Land Rover Web';

    &.cinza {
        color: $cinza;
        border: solid 1px $cinza;
    }

    &:hover {
        //background: $vermelho-500;
    }
}

.link.MuiButton-root {
    color: #fff;
    width: auto;
    font-family: 'Land Rover Web';

    &.cinza {
        color: $cinza;
    }
}

.MuiTextField-root {
    width: 100%;
    margin-bottom: 10px;

    .MuiInputLabel-root {
        color: #fff;
        text-transform: uppercase;
        font-family: 'Land Rover Web';

        &.MuiFormLabel-filled,
        &.Mui-focused {
            color: #fff;
        }
    }

    .MuiInputBase-input {}

    .MuiOutlinedInput-notchedOutline {
        border: solid 1px #fff;
    }

    .MuiInputBase-root {
        font-family: 'Land Rover Web';

        &:hover {
            .MuiOutlinedInput-notchedOutline {
                border-color: #fff;
            }
        }
    }

}

.MuiButtonBase-root .MuiBottomNavigationAction-label {
    font-family: 'Land Rover Web';
    text-transform: uppercase;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border-bottom: solid 2px #fff;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}