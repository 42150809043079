@import "../../variables";

.bottom-navigation {
    color: #000;
}

body.is-login-page,
body.is-tutorial-page {
    .MuiBottomNavigation-root {
        display: none;
    }
}

.MuiBottomNavigation-root {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 0 10px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
    height: auto !important;
    padding-top: 7px;
}