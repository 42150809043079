@import "../../variables";

section.page.parceiros {
    color: #000;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: $cinzaEscuro;
        margin-bottom: 20px;

        .bt-seta {
            color: $cinzaEscuro;
        }
    }

    .cards-parceiros {
        .card-parceiros {
            width: 100%;
            gap: 15px;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: solid 1px $cinza;

            img {
                width: auto;
                max-width: 40%;
                max-height: 70px;
                margin: auto;
            }

            .txt {
                width: 60%;

                h2 {
                    font-size: 14px;
                    color: $cinza;
                    font-weight: normal;
                }
            }
        }
    }
}