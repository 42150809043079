@import "../../variables";

section.videos {
    .cards-videos {
        .card-videos {
            width: 100%;
            margin-bottom: 15px;

            .img {
                width: 40%;
                background-size: cover;
                background-position: center center;
            }

            .txt {
                width: 60%;
                padding: 10px;
                color: $cinza;
                font-size: 0.85rem;
                font-weight: 500;

                h2 {
                    font-size: 0.98rem;
                    font-weight: 600;
                }
            }
        }
    }
}