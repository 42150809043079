@import "../../variables";

section.tutorial {
    padding: 0;

    .owl-carousel {
        .owl-item {
            .img {
                width: 100%;
                height: 45vh;
                background-size: cover;
                background-position: center center;
            }

            .txt {
                text-align: center;
                color: $cinza;
                padding-top: 20px;

                h2 {
                    font-size: 16px;
                    text-transform: uppercase;
                }

                h3 {
                    font-size: 14px;
                    font-weight: normal;
                }
            }
        }

        &.owl-theme {
            .owl-dots {
                .owl-dot {

                    &.active {
                        span {
                            background: $cinza;
                        }
                    }

                    span {
                        width: 25px;
                        height: 6px;
                        margin: 5px 12px;
                        background: $cinzaClaro;
                    }
                }
            }
        }
    }

    .bt {
        width: 100%;
        margin: 10px 0;
    }

    .link {
        text-decoration: underline;
    }
}