@import "../../variables";

.cadastro-component {
    width: 100%;
    padding-bottom: 30px;

    h3 {
        font-size: 1rem;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: #fff;
        font-weight: 500;
        text-align: center;
    }

    .MuiBox-root {
        margin-bottom: 15px;
        border-bottom: solid 2px #fff;

        svg {
            margin-right: 10px;
            position: relative;
            top: -3px;
        }

        .MuiFormControl-root {
            position: relative;
            top: 2px;
        }

        .MuiInputBase-root {

            &:before,
            &:hover:not(.Mui-disabled, .Mui-error):before {
                border-bottom: 1px solid rgba(255, 255, 255, 1);
            }

            input {
                color: #fff;
            }
        }
    }

    .MuiButton-root {
        width: 100%;
        padding: 0 10px;

        &.loading-true {
            opacity: 0.5;
            pointer-events: none;

            span.MuiCircularProgress-root {
                display: block;
            }
        }

        span.MuiCircularProgress-root {
            width: 20px !important;
            height: 20px !important;
            margin-right: 10px;
            color: #fff;
            display: none;
        }
    }

    .link {
        margin-top: 15px;
    }
}