@import "../../variables";

.interna-eventos.page {
    color: $cinzaEscuro;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: $cinzaEscuro;

        .bt-seta {
            color: $cinzaEscuro;
        }
    }

    img {
        width: 100%;
        border-radius: 7px;
    }

    h2 {
        font-size: 0.98rem;
        font-weight: 600;
        color: $cinzaEscuro;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    h3 {
        font-size: 0.85rem;
        font-weight: 500;
        margin: 0;
        justify-content: center;
        text-align: center;

        >span {
            margin-right: 10px;

            span {
                text-transform: uppercase;
                font-size: 0.5rem;
                display: block;
            }
        }
    }

    .tags {
        font-size: 0.73rem;
        text-align: center;
        padding-top: 7px;
    }

    h5 {
        font-size: 0.98rem;
        margin-top: 20px;
        margin-bottom: 7px;
    }

    h4 {
        display: flex;
        align-items: center;
        margin-bottom: 7px;

        svg {
            margin-right: 7px;
        }
    }
}