@import "../../variables";

.page.termos {
    background: #fff;
    color: $cinza;
    padding-top: 0;
    height: 100vh;
    @include easeOut();
    transition-duration: 1s;

    &.light-true {
        background: #000;
        color: #fff;

        .header-termo {
            background: #000;

            .MuiButtonBase-root {
                color: #fff;
            }
        }

        h1 {
            color: #fff;
        }
    }

    .header-termo {
        position: sticky;
        top: 0;
        background: #fff;

        .close-box {
            padding: 20px 0;
        }

        .MuiButtonBase-root {
            color: #000;
            min-height: 0;
            min-width: 0;
            margin: 0;
            padding: 0;
        }
    }

    h1 {
        color: $cinza;
        font-size: 1rem;
        @include easeOut();
    }

    strong {
        font-weight: 600;
    }

    .MuiFormControlLabel-root {
        width: 60px;
        margin: 0;
        height: 35px;
        position: relative;
        top: -9px;
    }
}