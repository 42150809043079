@import "../../variables";

.concierge.page {
    color: $cinzaEscuro;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: $cinzaEscuro;
        margin-bottom: 20px;

        >.MuiButtonBase-root {
            color: $cinzaEscuro;
            border-bottom: solid 1px;
            border-radius: 0;
        }

        .esq {
            width: calc(100% - 75px);
        }

        .bt-seta {
            color: $cinzaEscuro;
        }
    }

    .msgs {
        background: $cinzaClaro;
        overflow-y: auto;
        max-height: calc(100vh - 215px);
        min-height: calc(100vh - 215px);
        padding: 15px 0;
        display: flex;
        flex-direction: column-reverse;

        .box-msg {
            margin-bottom: 10px;

            &.defender {
                >img {
                    margin-right: 10px;
                }
            }

            >img {
                width: 35px;
                margin-left: 10px;
            }

            .msg {
                background: #fff;
                border-radius: 4px;
                padding: 10px;
            }
        }
    }

    form {
        width: 100%;
        position: fixed;
        bottom: 64px;

        textarea {
            width: calc(100% - 30px) !important;
            resize: none;
            margin-right: 10px;
            border: none;
            padding: 14px 0px;
        }

        .MuiButtonBase-root {
            &[disabled] {
                opacity: 0.3;
            }

            img {
                width: 20px;
            }
        }
    }
}