@import "../../variables";

.login-page {
    background: url(../../assets/login/bg-login.jpg) no-repeat center top / 100%;
    background-color: #000;
    background-position-y: -70px;
    color: #fff;
    min-height: 100vh;
    @include easeOut();
    transition-duration: 1s;

    &.tenho-conta-true {
        background: url(../../assets/login/bg-login2.jpg) no-repeat center top / 100%;
        background-position-y: -70px;
        background-color: #000;

        .cadastro-component {
            opacity: 0;
            visibility: hidden;
        }

        .login-component {
            opacity: 1;
            visibility: visible;
        }
    }

    .login-component {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 0;
        bottom: 50px;
        width: 100%;
        padding: 0 25px;
        @include easeOut();
        transition-duration: 1s;
    }

    .cadastro-component {
        opacity: 1;
        visibility: visible;
        position: absolute;
        left: 0;
        bottom: 50px;
        width: 100%;
        padding: 0 25px;
        @include easeOut();
        transition-duration: 1s;
    }

    .box-logo {
        position: absolute;
        text-align: center;
        left: 15px;
        top: 50px;
        width: calc(100% - 30px);

        img {
            width: 70%;
            display: inline-block;
        }
    }
}