@import "../../variables";

.page.preferencia {
    color: $cinzaEscuro;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: $cinzaEscuro;
        margin-bottom: 0;
    }

    h2 {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 20px;
        border-bottom: solid 1px;
        padding-bottom: 10px;
    }

    .itens {
        gap: 10px;

        >label {
            width: calc(50% - 15px);
            font-size: 14px;
            overflow-wrap: anywhere;
        }
    }

    .MuiButtonBase-root {
        padding: 9px;

        .css-jsexje-MuiSwitch-thumb {
            border-radius: 5px;
        }
    }

    .MuiSwitch-root {
        padding: 5px;
        margin-right: 7px;

        .MuiSwitch-thumb {
            background: $cinzaEscuro;
        }

        .MuiSwitch-track {
            background: no-repeat;
            border: solid 1px $cinza;
        }

        .Mui-checked {
            +.MuiSwitch-track {
                background: $cinzaClaro;
                border: none;
            }
        }
    }

    .bt.MuiButton-root {
        width: 100%;
        margin-top: 20px;

        &.cinza {
            background: $cinza;
            color: #fff;
            border: solid 1px $cinza;
        }

        &:hover {
            //background: $vermelho-500;
        }
    }

    .MuiTextField-root {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 20px;
    
        .MuiInputLabel-root {
            color: $cinza;
            text-transform: uppercase;
    
            &.MuiFormLabel-filled,
            &.Mui-focused {
                color: $cinza;
            }
        }
    
        .MuiInputBase-input {}
    
        .MuiOutlinedInput-notchedOutline {
            border: solid 1px $cinza;
        }
    
        .MuiInputBase-root {
            &:hover {
                .MuiOutlinedInput-notchedOutline {
                    border-color: $cinza;
                }
            }
        }
    
    }
}